import React from "react";
import { Link } from "react-router-dom";
import "./Header.css";

const Header = () => {
  return (
    <header className="header">
      <div className="header-content">
        <Link to="/" className="logo">
          <span className="logo-text">&lt;KorLegends /&gt;</span>
        </Link>
        <nav className="header-nav-container">
          <ul className="header-nav-list">
            <li>
              <Link to="/output" className="header-nav-link">
                &lt;OutPut /&gt;
              </Link>
            </li>
            <li>
              <Link to="/goal" className="header-nav-link">
                &lt;Goal /&gt;
              </Link>
            </li>
            <li>
              <Link to="/about" className="header-nav-link">
                &lt;About_Us /&gt;
              </Link>
            </li>
            <li>
              <Link to="/contact" className="header-nav-link">
                &lt;Contact_Us /&gt;
              </Link>
            </li>
          </ul>
        </nav>
      </div>
    </header>
  );
};

export default Header;
